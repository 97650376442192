import { BasicBooking_loadingPreference } from '../../../../common/graphql/fragments/gql/BasicBooking';
import { hasSenderReceiverCountries } from '../../../../common/update/utils/hasSenderReceiverCountries';
import { GoodsApproval, GoodsApprovalType } from '../../../../common/utils/GoodsApproval';
import { resolveHazardousGoodsApproved } from '../../../../common/utils/hazardousGoodsUtil';
import { resolveLoadingPreferenceApproved } from '../../../../common/utils/loadingPreferenceUtil';
import { BookingStatus, ColumnId, CustomerCustomsStatus } from '../../../../gql/graphql';
import {
  getImportExportSettingsFromMinimalBooking,
  getImportExportSettingsFromRoute,
} from '../../../import-export/util/ImportExportSettingsGetters';
import { ApprovalConfig, getApprovalInfo } from './approvalInfo';
import { MinimalBooking } from './minimalBooking';
import { shouldShowHazardousGoodsApproval } from '../../../../common/utils/shouldShowHazardousGoodsApproval';

export type BookingFields = Set<ColumnId>;

export function union<T>(setA: Set<T>, setB: Set<T>): Set<T> {
  let _union = new Set<T>(setA);
  for (let elem of setB) {
    _union.add(elem);
  }
  return _union;
}

export function intersection<T>(setA: Set<T>, setB: Set<T>): Set<T> {
  const _intersection = new Set<T>();
  for (const elem of setB) {
    if (setA.has(elem)) {
      _intersection.add(elem);
    }
  }
  return _intersection;
}

export const getFieldsToEdit = (
  shownFields: BookingFields,
  editableFields: ColumnId[],
): BookingFields => {
  if (editableFields.length === 0) {
    return shownFields;
  } else {
    return intersection(shownFields, new Set(editableFields));
  }
};

export const getFieldsToShow = (
  minimalBooking: MinimalBooking,
  { alwaysShowTrailerReg }: { alwaysShowTrailerReg: boolean } = { alwaysShowTrailerReg: false },
): BookingFields => {
  const { route, vehicleType, hasCashCustomer } = minimalBooking;

  const columns: BookingFields = new Set<ColumnId>();
  columns.add(ColumnId.PaymentStatus);
  columns.add(ColumnId.Status);
  columns.add(ColumnId.QuayStatus);
  columns.add(ColumnId.SpecialProperty);
  columns.add(ColumnId.EstimatedPickupTime);
  columns.add(ColumnId.BookingNo);
  columns.add(ColumnId.RouteCode);
  columns.add(ColumnId.CreatedBy);
  columns.add(ColumnId.ModifiedBy);
  columns.add(ColumnId.DateUpdated);
  columns.add(ColumnId.DateCreated);
  columns.add(ColumnId.CustIndex);
  columns.add(ColumnId.DaysOnQuay);
  columns.add(ColumnId.ETA);
  columns.add(ColumnId.DepartureDate);
  columns.add(ColumnId.DepartureTime);

  columns.add(ColumnId.VehicleTypeCode);

  columns.add(ColumnId.Length);
  columns.add(ColumnId.Width);
  columns.add(ColumnId.Height);

  if (
    getImportExportSettingsFromMinimalBooking(
      minimalBooking,
      getImportExportSettingsFromRoute(minimalBooking.route, 'importReference'),
    )
  ) {
    columns.add(ColumnId.ImportReference);
  }
  if (
    getImportExportSettingsFromMinimalBooking(
      minimalBooking,
      getImportExportSettingsFromRoute(minimalBooking.route, 'exportReference'),
    )
  ) {
    columns.add(ColumnId.ExportReference);
  }

  if (vehicleType?.useTrailerReg || alwaysShowTrailerReg) {
    columns.add(ColumnId.TrailerRegNo);
  }
  if (vehicleType?.useVehicleReg) {
    columns.add(ColumnId.VehicleRegNo);
  }

  if (vehicleType?.useTradeWeight) {
    columns.add(ColumnId.TradeWeight);
  }

  if (vehicleType?.useCargoWeight) {
    if (hasCashCustomer) {
      columns.add(ColumnId.CargoWeight);
      if (route?.useSenderReceiverCountry && hasSenderReceiverCountries(minimalBooking)) {
        columns.add(ColumnId.ReceiverCountryCode);
        columns.add(ColumnId.SenderCountryCode);
      }
    } else if (route?.useCargoWeight) {
      columns.add(ColumnId.CargoWeight);
    }
  }

  if (vehicleType?.useNoOfDrivers) {
    columns.add(ColumnId.NoOfDrivers);
  } else {
    // Cash customer only
    // columns.add(ColumnId.NoOfAdults);
    // columns.add(ColumnId.NoOfChildren);
    // columns.add(ColumnId.NoOfInfants);
  }

  if (vehicleType?.useHazardous && route?.useHazardousGoods) {
    columns.add(ColumnId.HazardousGoods);
  }

  if (vehicleType?.useAnimals && route?.useLivestockType) {
    columns.add(ColumnId.LivestockTypeCode);
  }

  if (vehicleType?.useTemperature) {
    columns.add(ColumnId.Temperature);
  }

  columns.add(ColumnId.CustomerReference);

  columns.add(ColumnId.LoadingPreferenceCode);

  columns.add(ColumnId.LoadingListMessage);

  if (route?.useCustomsClassification) {
    columns.add(ColumnId.CustomsClassification);
  }

  if (minimalBooking.showDaysOnQuay) {
    columns.add(ColumnId.DaysOnQuay);
  }

  if (
    minimalBooking.customerCustomsStatus &&
    minimalBooking.customerCustomsStatus !== CustomerCustomsStatus.NotApplicable
  ) {
    columns.add(ColumnId.CustomerCustomsStatus);
  }

  return columns;
};

export const getLoadingPreferenceApprovalInfo = (
  loadingPreference: BasicBooking_loadingPreference | null,
  loadingPreferenceStatusCode: string,
): ApprovalConfig | null => {
  const loadingPreferenceApproval: GoodsApproval = {
    status: resolveLoadingPreferenceApproved(loadingPreference, loadingPreferenceStatusCode),
    type: GoodsApprovalType.LoadingPreference,
  };

  return getApprovalInfo(loadingPreferenceApproval, undefined, true);
};

export const getHazardousGoodsApprovalInfo = (
  hazardousGoods: boolean,
  hazardousGoodsApproved: boolean,
  bookingStatus: BookingStatus | null,
): ApprovalConfig | null => {
  if (!shouldShowHazardousGoodsApproval(bookingStatus, hazardousGoodsApproved)) {
    return null;
  }

  const hazardousGoodsApproval: GoodsApproval = {
    status: resolveHazardousGoodsApproved(false, hazardousGoods, hazardousGoodsApproved),
    type: GoodsApprovalType.Hazardous,
  };
  return getApprovalInfo(hazardousGoodsApproval, bookingStatus, true);
};

export const isHidden = (fields: BookingFields, column: ColumnId) => {
  return !fields.has(column);
};

export const isVisible = (fields: BookingFields, column: ColumnId) => {
  return !isHidden(fields, column);
};

export const areAnyVisible = (fields: BookingFields, columns: ColumnId[]) => {
  return columns.some(column => isVisible(fields, column));
};
