import { PropsWithChildren } from 'react';
import { batch } from 'react-redux';
import { rehydratePageSettings } from '../../../services/page-settings/pageSettingsActions';
import { transformToPageSettingsRehydratePayload } from '../../../services/page-settings/sagas/utils/transformToPageSettingsRehydratePayload';
import { transformToTourReducerState } from '../../../services/page-settings/sagas/utils/transformToTourReducerState';
import { initializeSettingsForm, setAccount } from '../../../services/settings/settingsReducer';
import { requestInitializeTour } from '../../../services/tour/tourActions';
import { useAppDispatch } from '../../../store/appDispatch';
import { browserHistory } from '../../../store/utils/history';
import { transformToSettingsFormState } from '../../../use-cases/settings/utils/transformToSettingsFormState';
import { suspend } from '../../../vendor/suspend-react';
import { fetchAccount } from '../../api';
import { assertIsDefined } from '../../asserts/assert';
import { useAuthState } from '../../hooks/useAuthState';
import { getMainPage } from '../../routes/routeUtils';
import { getModalRouteFromPath, redirectModal } from '../../routes/tryModal';

export const AccountSettingsGate = ({ children }: PropsWithChildren) => {
  const dispatch = useAppDispatch();
  const { isNewSession } = useAuthState();

  suspend(async () => {
    const account = await fetchAccount();

    assertIsDefined(account, 'Error fetching user account');

    const { accountSettings, pageSettings } = account;

    batch(() => {
      dispatch(setAccount(account));
      dispatch(initializeSettingsForm(transformToSettingsFormState(accountSettings)));
      dispatch(rehydratePageSettings(transformToPageSettingsRehydratePayload(pageSettings)));
      dispatch(requestInitializeTour(transformToTourReducerState(pageSettings.tour)));
    });

    // Redirect to main page depending on permissions
    const oldLocation = window.location.pathname;
    const returnToParam = new URLSearchParams(window.location.search).get('returnTo');
    const newLocation = returnToParam || getMainPage(account.permissions);
    const modalLocation = isNewSession ? newLocation : oldLocation;

    const modalRoute = getModalRouteFromPath(modalLocation);

    if (modalRoute) {
      redirectModal(modalRoute, modalLocation);
    } else {
      if (isNewSession) {
        browserHistory.replace(newLocation);
      }
    }
  }, ['account']);

  return children;
};
