import { first, map } from 'lodash';
import { matchRoutes, RouteMatch } from 'react-router-dom';
import { browserHistory } from '../../store/utils/history';
import { ModalRouteLocations, modalRouteLocations } from './AppRoutes';

export const getModalRouteFromPath = (targetPathname: string) => {
  const r = matchRoutes(
    map(modalRouteLocations, (route, key) => ({ path: route.path.pathname, id: key })),
    targetPathname,
  );

  return first(r);
};

export const redirectModal = (route: RouteMatch, targetPathname: string) => {
  const appRouteKey = route.route.id as keyof ModalRouteLocations;
  const modalRoute = modalRouteLocations[appRouteKey];

  browserHistory.replace(modalRoute.state.background);
  browserHistory.push(targetPathname, modalRoute.state);
};
