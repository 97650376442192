import { useMemo } from 'react';
import {
  BasicBooking,
  BasicBooking_sailing_route_exportSettings,
  BasicBooking_sailing_route_importSettings,
} from '../../../../common/graphql/fragments/gql/BasicBooking';
import { Route_ukCustomsClassifications } from '../../../../common/graphql/fragments/gql/Route';
import { customsClassificationDropdownEmptyOption } from '../../../../common/graphql/useCustomsClassificationsOptions';
import { useBookingFormInstanceId } from '../../../../common/update-information/BookingFormInstanceIdProvider';
import { useUpdateFormState } from '../../../../common/update-information/hooks/useUpdateFormState';
import { CustomerCustomsStatus } from '../../../../gql/graphql';
import { CreateCandidate, CreateFormState } from '../../../../services/create/createFormReducer';
import { useCreateFormState } from '../../../../services/create/hooks/useCreateFormState';
import { BookingFormState } from '../../../../services/update-form/updateFormReducer';

export const MINIMAL_BOOKING_SYMBOL = Symbol('MINIMAL_BOOKING_SYMBOL');

export interface MinimalBooking {
  minimalBooking: typeof MINIMAL_BOOKING_SYMBOL;
  bookingNo?: number;
  noOfDrivers?: number;
  showDaysOnQuay?: boolean;
  noOfPlugins?: number;
  route?: {
    code: string;
    useCargoWeight: boolean;
    useCustomsClassification: boolean;
    useHazardousGoods: boolean;
    useLivestockType: boolean;
    useSenderReceiverCountry: boolean;
    importSettings: BasicBooking_sailing_route_importSettings | null;
    exportSettings: BasicBooking_sailing_route_exportSettings | null;
    ukCustomsClassifications: Route_ukCustomsClassifications[];
  };
  departureDate?: string;
  departureTime?: string;
  vehicleType?: {
    useAnimals: boolean;
    useCargoWeight: boolean;
    useHazardous: boolean;
    useNoOfDrivers: boolean;
    useTemperature: boolean;
    useTradeWeight: boolean;
    useTrailerReg: boolean;
    useVehicleReg: boolean;
  };
  customerCustomsStatus?: CustomerCustomsStatus;
  customsClassificationCode?: string;
  cargoWeight?: number;
  hasCashCustomer?: boolean;
}

export const transformUpdateBookingFormStateToMinimalBooking = (
  formState: BookingFormState,
): MinimalBooking => {
  const {
    route,
    vehicleType,
    showDaysOnQuay,
    noOfPlugins,
    cargoWeight,
    sailing,
    departureDate,
    customsClassification,
    bookingNo,
  } = formState;
  return {
    minimalBooking: MINIMAL_BOOKING_SYMBOL,
    noOfDrivers: formState.noOfDrivers,
    bookingNo,
    showDaysOnQuay,
    noOfPlugins,
    route,
    vehicleType,
    cargoWeight,
    departureDate,
    departureTime: sailing?.departureTime,
    customsClassificationCode:
      customsClassification === customsClassificationDropdownEmptyOption.data
        ? undefined
        : customsClassification?.value,
    hasCashCustomer: formState.customer?.isCashCustomer,
  };
};

export const transformCreateBookingFormStateToMinimalBooking = (
  formState: CreateFormState,
  createCandidate: CreateCandidate,
): MinimalBooking => {
  const { route, sailing, departureDate, customer } = formState;
  const { vehicleType, numberOfDrivers, hasElectricalPlugin, cargoWeight, customsClassification } =
    createCandidate;
  return {
    minimalBooking: MINIMAL_BOOKING_SYMBOL,
    noOfDrivers: numberOfDrivers,
    showDaysOnQuay: false,
    noOfPlugins: hasElectricalPlugin ? 1 : 0,
    route: route ?? undefined,
    vehicleType: vehicleType ?? undefined,
    cargoWeight,
    departureDate,
    departureTime: sailing?.departureTime,
    customsClassificationCode:
      customsClassification === customsClassificationDropdownEmptyOption.data
        ? undefined
        : customsClassification?.value,
    hasCashCustomer: customer?.isCashCustomer,
  };
};

export const transformBookingToMinimalBooking = (booking: BasicBooking): MinimalBooking => {
  const {
    sailing,
    vehicleType,
    bookingNo,
    customerCustomsStatus,
    showDaysOnQuay,
    noOfPlugins,
    noOfDrivers,
    cargoWeight,
    customsClassification,
    customer,
  } = booking;
  return {
    minimalBooking: MINIMAL_BOOKING_SYMBOL,
    bookingNo,
    showDaysOnQuay,
    customerCustomsStatus,
    noOfPlugins,
    noOfDrivers,
    route: sailing.route,
    vehicleType,
    cargoWeight,
    departureDate: sailing.departureDate,
    departureTime: sailing.departureTime,
    customsClassificationCode:
      customsClassification === customsClassificationDropdownEmptyOption.data
        ? undefined
        : customsClassification?.value,
    hasCashCustomer: customer.isCashCustomer,
  };
};

export const useUpdateFormStateMinimalBooking = (): MinimalBooking | undefined => {
  const { formState } = useUpdateFormState(useBookingFormInstanceId());
  return useMemo(() => {
    if (!formState) {
      return undefined;
    }
    return transformUpdateBookingFormStateToMinimalBooking(formState);
  }, [formState]);
};

export const useCreateFormStateMinimalBooking = (index: number): MinimalBooking | undefined => {
  const { formState } = useCreateFormState();
  return useMemo(() => {
    const createCandidate = formState.bookings[index];
    if (!createCandidate) {
      return undefined;
    }
    return transformCreateBookingFormStateToMinimalBooking(formState, createCandidate);
  }, [formState, index]);
};
