import {
  AuthError,
  getAuthError,
  getAuthResult,
  isAuth0SessionInformationFetched,
  isAuthenticated,
} from '../../services/authentication/authReducer';
import { StoreState } from '../../store/storeState';
import { useShallowEqualSelector } from './useShallowEqualSelector';

interface StateProps {
  isAuthenticated: boolean;
  sessionFetched: boolean;
  error: AuthError | null;
  isNewSession: boolean;
}
const mapState = (state: StoreState): StateProps => {
  return {
    isAuthenticated: isAuthenticated(state),
    sessionFetched: isAuth0SessionInformationFetched(state),
    error: getAuthError(state),
    isNewSession: getAuthResult(state)?.isNewSession ?? false,
  };
};

export const useAuthState = () => {
  const { isAuthenticated, sessionFetched, error, isNewSession } =
    useShallowEqualSelector(mapState);

  return { isAuthenticated, sessionFetched, error, isNewSession };
};
