import { ReactNode } from 'react';
import { BasicBooking } from '../../../../common/graphql/fragments/gql/BasicBooking';
import { DetailedBooking } from '../../../../common/graphql/fragments/gql/DetailedBooking';
import { resolveCustomerLabel } from '../../../../common/utils/customerUtils';
import { resolveCustomsClassificationText } from '../../../../common/utils/customsClassificationUtil';
import { formatDate, formatTime } from '../../../../common/utils/dates/dateUtils';
import { GoodsApprovalType } from '../../../../common/utils/GoodsApproval';
import {
  resolveHazardousGoodsApproved,
  resolveHazardousGoodsText,
} from '../../../../common/utils/hazardousGoodsUtil';
import { resolveLivestockTypeText } from '../../../../common/utils/livestockUtil';
import {
  resolveLoadingPreferenceApproved,
  resolveLoadingPreferenceText,
} from '../../../../common/utils/loadingPreferenceUtil';
import { resolveTemperatureText } from '../../../../common/utils/temperatureUtil';
import { ColumnId } from '../../../../gql/graphql';
import { getApprovalHeader } from './approvalInfo';
import { getFieldsToShow, isVisible } from './detailsUtils';
import { transformBookingToMinimalBooking } from './minimalBooking';
import { getImportExportReferenceText } from '../../../../common/pdf/utils';

export interface RowType {
  className?: string;
  name: string;
  value: string;
  collapse?: boolean;
  suffix?: ReactNode;
}

export const bookingDetailsRows = (booking: BasicBooking | DetailedBooking, dateFormat: string) => {
  const minimalBooking = transformBookingToMinimalBooking(booking);
  const fields = getFieldsToShow(minimalBooking, {
    alwaysShowTrailerReg: Boolean(booking.trailerRegNo),
  });
  const rows: RowType[] = [];

  rows.push({
    className: 't_booking_no_details_view',
    name: 'Booking number',
    value: String(booking.bookingNo),
  });

  rows.push({
    name: 'Status',
    value: booking.status,
  });

  if (booking.estimatedPickupTime) {
    rows.push({
      name: 'Pick up time',
      value: `est. ${formatTime(booking.estimatedPickupTime)}`,
    });
  }

  if (isVisible(fields, ColumnId.RouteCode)) {
    rows.push({
      name: 'Route',
      value: booking.sailing.route.code,
    });
  }

  if (isVisible(fields, ColumnId.DepartureDate)) {
    rows.push({
      name: 'Date',
      value: formatDate(booking.sailing.departureDate, dateFormat),
    });
  }

  if (isVisible(fields, ColumnId.DepartureTime)) {
    rows.push({
      name: 'Departure time (local)',
      value: booking.sailing.departureTime,
    });
  }

  if (isVisible(fields, ColumnId.VehicleRegNo)) {
    rows.push({
      className: 't_vehicle_reg_details_view',
      name: 'Vehicle registration',
      value: booking.vehicleRegNo,
    });
  }

  if (isVisible(fields, ColumnId.TrailerRegNo)) {
    rows.push({
      className: 't_trailer_reg_details_view',
      name: 'Trailer registration',
      value: booking.trailerRegNo,
    });
  }

  if (isVisible(fields, ColumnId.VehicleTypeCode)) {
    rows.push({
      className: 't_vehicle_type_details_view',
      name: 'Vehicle type',
      value: booking.vehicleType.description,
    });
  }

  if (isVisible(fields, ColumnId.Length)) {
    rows.push({
      className: 't_length_details_view',
      name: 'Length',
      value: `${booking.length} m`,
    });
  }

  if (isVisible(fields, ColumnId.Width)) {
    rows.push({
      className: 't_width_details_view',
      name: 'Width',
      value: `${booking.width} m`,
    });
  }

  if (isVisible(fields, ColumnId.Height)) {
    rows.push({
      className: 't_height_details_view',
      name: 'Height',
      value: `${booking.height} m`,
    });
  }

  if (isVisible(fields, ColumnId.NoOfDrivers)) {
    rows.push({
      name: 'Drivers',
      value: String(booking.noOfDrivers),
    });
  }

  if (isVisible(fields, ColumnId.CustomsClassification)) {
    rows.push({
      name: 'UK customs procedure',
      value: resolveCustomsClassificationText(booking.customsClassification),
    });
  }

  if (isVisible(fields, ColumnId.ExportReference)) {
    rows.push({
      name: 'Export',
      value: getImportExportReferenceText(minimalBooking, booking, 'exportReference'),
    });
  }

  if (isVisible(fields, ColumnId.ImportReference)) {
    rows.push({
      name: 'Import',
      value: getImportExportReferenceText(minimalBooking, booking, 'importReference'),
    });
  }

  if (isVisible(fields, ColumnId.CustIndex)) {
    rows.push({
      name: 'Account',
      value: resolveCustomerLabel(booking.customer.custNo, booking.customer.custIndex),
    });
  }

  if (isVisible(fields, ColumnId.CustomerReference)) {
    rows.push({
      name: 'Customer reference',
      value: booking.customerReference,
    });
  }

  if (isVisible(fields, ColumnId.CargoWeight)) {
    rows.push({
      name: 'Cargo weight',
      value: `${booking.cargoWeight} kg`,
    });
  }

  if ('carbonFootprint' in booking && booking.carbonFootprint != null) {
    rows.push({
      name: 'Carbon footprint',
      value: `${booking.carbonFootprint.value}`,
      suffix: (
        <>
          {' '}
          kg CO<sub>2</sub>
        </>
      ),
    });
  }

  if (isVisible(fields, ColumnId.SenderCountryCode)) {
    rows.push({
      name: 'Sender country',
      value: String(booking.senderCountry?.label),
    });
  }

  if (isVisible(fields, ColumnId.ReceiverCountryCode)) {
    rows.push({
      name: 'Receiver country',
      value: String(booking.receiverCountry?.label),
    });
  }

  if (isVisible(fields, ColumnId.Temperature)) {
    rows.push({
      name: 'Temperature onboard',
      value: resolveTemperatureText(booking.noOfPlugins, booking.pluginTemperature),
    });
  }

  if (isVisible(fields, ColumnId.HazardousGoods)) {
    const approvalHeader = getApprovalHeader({
      status: resolveHazardousGoodsApproved(
        false,
        booking.hazardousGoods,
        booking.hazardousGoodsApproved,
      ),
      type: GoodsApprovalType.Hazardous,
    });
    rows.push({
      name: 'Hazardous goods',
      value:
        approvalHeader === ''
          ? resolveHazardousGoodsText(booking.hazardousGoods)
          : `${resolveHazardousGoodsText(booking.hazardousGoods)} (${approvalHeader})`,
    });
  }

  if (isVisible(fields, ColumnId.LivestockTypeCode)) {
    rows.push({
      name: 'Animals',
      value: resolveLivestockTypeText(booking.livestockType),
    });
  }

  if (isVisible(fields, ColumnId.LoadingPreferenceCode)) {
    const approvalHeader = getApprovalHeader({
      status: resolveLoadingPreferenceApproved(
        booking.loadingPreference,
        booking.loadingPreferenceStatusCode,
      ),
      type: GoodsApprovalType.LoadingPreference,
    });
    rows.push({
      name: 'Loading preference',
      value:
        approvalHeader === ''
          ? resolveLoadingPreferenceText(booking.loadingPreference)
          : `${resolveLoadingPreferenceText(booking.loadingPreference)} (${approvalHeader})`,
    });
  }

  if (isVisible(fields, ColumnId.LoadingListMessage)) {
    rows.push({
      name: 'Loading note',
      collapse: true,
      value: booking.loadingListMessage,
    });
  }

  if (isVisible(fields, ColumnId.TradeWeight)) {
    rows.push({
      name: 'Trade weight',
      value: `${booking.tradeWeight} kg`,
    });
  }

  return rows;
};
